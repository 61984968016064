export const paths = {
  HOME: '/',
  LOGOUT: '/logout',
  LOGIN_CALLBACK: '/login/redirect',
  LOGOUT_CALLBACK: '/logout/redirect',
  LOGOUT_CALLBACK_WITH_SUCCESS_MODAL: '/logout/callback',
  LOGOUT_CALLBACK_WITH_ROLE_MODAL: '/logout/role',
  CALENDAR_ONBOARDING: '/calendar/onboarding',
  CALENDAR_CURRENT_TOPICS: '/calendar',
  CALENDAR_CURRENT_TOPICS_LESSON: ({ date, lessonId }: { date: Date; lessonId: number }) =>
    `/calendar?date=${encodeURIComponent(date.toISOString())}&lessonId=${encodeURIComponent(
      lessonId,
    )}`,
  CALENDAR_UNREALIZED_TOPICS: '/calendar/unrealized',
  CALENDAR_CORE_CURRICULUM: '/calendar/curriculum',
  MATERIALS: '/calendar/materials',
  LIBRARY: '/library',
  LIBRARY_NEW: '/library/new',
  MY_ACCOUNT: '/my-account',
  MY_GROUPS: '/my-account/my-groups',
  ADD_CLASS: '/my-account/add-class',
  ADD_CLASS_SCHOOL_YEAR_ID: (schoolYearId: number | string) =>
    `/my-account/add-class/${schoolYearId}`,
  ADD_SUBJECT: (groupId: string) => `/my-account/add-subject/${groupId}`,
  ABOUT_ME: '/my-account/about-me',
  TUTORIAL: '/my-account/tutorial',
  TECHNICAL_SUPPORT: '/my-account/technical-support',
  LEGAL_INFO: '/my-account/legal-info',
  NOTIFICATIONS: '/notifications',
  TEST: '/test',
  REMINDERS: '/reminders',
  REMINDER_NEW: '/reminders/new',
  REMINDER_EDIT: (id: string) => `/reminders/edit/${id}`,
};
